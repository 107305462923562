import React from "react";

import { formattedDayString } from "@/utils";

import * as styles from "./Meta.module.scss";

interface Props {
  date: number;
}

const Meta: React.FC<Props> = ({ date }: Props) => (
  <div className={styles.meta}>
    <p className={styles.date}>
      Published {formattedDayString.getFormattedDateString(date)}
    </p>
  </div>
);

export default Meta;
